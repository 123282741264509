import React from 'react';
import type { ComponentProps } from 'react';
import { SvgIcon } from '@material-ui/core';

type SvgIconProps = ComponentProps<typeof SvgIcon>;

export default function SearchIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon fontSize={'inherit'} viewBox={'0 0 31 32'} {...props}>
      <path d="M0.291 16.388c-1.261-5.915 1.649-11.927 7.176-14.545 5.527-2.521 12.024-0.97 15.806 3.879 3.685 4.752 3.588 11.442-0.388 16.097l7.758 7.758c0.291 0.194 0.388 0.485 0.388 0.776s-0.097 0.582-0.388 0.776c-0.194 0.291-0.485 0.388-0.776 0.388s-0.582-0.097-0.776-0.388l-7.758-7.661q-0.873 0.679-1.842 1.261-1.067 0.582-2.133 0.97-0.97 0.388-2.133 0.582-1.067 0.194-2.23 0.194c-6.109 0-11.345-4.17-12.703-10.085zM8.921 3.782c-1.358 0.485-2.521 1.261-3.491 2.23-0.97 1.067-1.745 2.23-2.327 3.491-0.485 1.261-0.776 2.618-0.776 4.073 0 4.267 2.618 8.145 6.594 9.794s8.533 0.679 11.636-2.327c3.006-3.006 3.976-7.564 2.327-11.539-1.745-3.976-5.624-6.594-9.891-6.594-1.455 0-2.812 0.291-4.073 0.873z"></path>
    </SvgIcon>
  );
}
