import React, {
  Dispatch,
  memo,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';

import MobileNavigationDrawer from './MobileNavigationDrawer';
import Menu from 'components/Menu/Menu';
import RightNavigationMenu from 'components/Header/RightNavigationMenu/RightNavigationMenu';
import SearchBar, { SearchBarRefType } from 'components/SearchBar/SearchBar';
import { useSearchBar } from 'hooks/useSearchBar';
import { MOBILE_AND_TABLET } from 'settings/mediaQuery';

import styles from './NavigationMenu.module.scss';

/**
 * Megamenu - Nav bar with global category and its content <br />
 * Contains NavigationMenuDesktop and NavigationMenuMobile
 */
function NavigationMenu({
  show,
  visibleSticky: sticky,
}: {
  /** Show content */
  show: boolean;
  visibleSticky: boolean;
}): JSX.Element | null {
  const [openSearch, setOpenSearch] = useState(false);
  const isMobileAndTablet = useMediaQuery({ query: MOBILE_AND_TABLET });
  const openSearchBar = useCallback(() => {
    setOpenSearch(true);
  }, []);

  return (
    <>
      {isMobileAndTablet && <MobileNavigationDrawer isDrawerOpen={show} />}
      <div
        className={classnames(styles.wrapper, {
          [styles.sticky]: sticky,
          [styles.searchOpen]: openSearch,
        })}
      >
        <div className={styles.container}>
          <Menu
            classes={{
              menuContainer: styles.menu,
              menuItem: {
                container: styles.menuItem,
              },
            }}
          />
          <div className={styles.rightSide}>
            {sticky && !isMobileAndTablet && (
              <SearchBarWrapper
                isSticky={sticky}
                isSearchBarVisible={openSearch}
                onFocus={openSearchBar}
                setOpenSearch={setOpenSearch}
              />
            )}
            <div className={styles.rightLinks}>
              <RightNavigationMenuWrapper
                isSticky={sticky}
                isOpenSearch={openSearch}
                openSearchBar={openSearchBar}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function RightNavigationMenuWrapper({
  isSticky,
  openSearchBar,
}: {
  isSticky: boolean;
  isOpenSearch: boolean;
  openSearchBar: () => void;
}) {
  const { searchBarRef } = useSearchBar();

  return (
    <RightNavigationMenu
      sticky={isSticky}
      onSearchClick={() => {
        openSearchBar();
        setTimeout(() => searchBarRef.current?.focusSearchInput());
      }}
    />
  );
}

function SearchBarWrapper({
  isSticky,
  isSearchBarVisible,
  onFocus,
  setOpenSearch,
}: {
  isSticky: boolean;
  isSearchBarVisible: boolean;
  onFocus: () => void;
  setOpenSearch: Dispatch<SetStateAction<boolean>>;
}) {
  const { searchBarRef, searchKeyword } = useSearchBar();
  const closeSearchBar = useCallback(() => {
    searchBarRef.current?.clearSearchInput();
    setOpenSearch(false);
  }, [searchBarRef, setOpenSearch]);

  useEffect(() => {
    if (searchKeyword && !isSearchBarVisible) {
      setOpenSearch(true);
    }
  }, [searchKeyword, isSearchBarVisible]);

  useEffect(() => {
    return () => {
      setOpenSearch(false);
    };
  }, []);

  return (
    <SearchBar
      ref={searchBarRef as MutableRefObject<SearchBarRefType>}
      sticky={isSticky}
      onFocus={onFocus}
      onClickAway={closeSearchBar}
      onClose={closeSearchBar}
      onRedirect={closeSearchBar}
      placementId={'headerContainerLayout'}
      classes={{
        container: styles.searchBar,
        inputGroup: styles.searchInputGroup,
      }}
    />
  );
}

export default memo(NavigationMenu);
