import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import NavigationMenu from 'components/Menu/NavigationMenu/NavigationMenu';
import Header from 'components/Header/Header';
import { isProductSheet } from 'services/validation';
import { LARGE_LAPTOP_AND_DESKTOP, MOBILE, TABLET } from 'settings/mediaQuery';

import styles from './HeaderLayout.module.scss';
import SearchBarProvider from '../providers/SearchBarProvider';

export default function HeaderLayout(): JSX.Element {
  const [displayMenu, setDisplayMenu] = useState(false);
  const [visibleSticky, setVisibleSticky] = useState(false);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const headerRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  // Use mediaQuery to simplify resize detection
  const isDesktop = useMediaQuery({ query: LARGE_LAPTOP_AND_DESKTOP });
  const isTablet = useMediaQuery({ query: TABLET });
  const isMobile = useMediaQuery({ query: MOBILE });

  const canBeSticky = !(isProductSheet(pathname) && isDesktop);
  const isSticky = canBeSticky && visibleSticky;

  useEffect(() => {
    setHeaderHeight(0); // Reset header height is size of screen has changed
  }, [isDesktop, isTablet, isMobile]);

  const handleScroll = useCallback(() => {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    const headerRefCurrent = headerRef.current;
    const headerHeight = headerRefCurrent?.getBoundingClientRect()?.height;
    const isVisibleSticky = isDesktop ? scrollTop > 166 : scrollTop > 0;

    if (!isVisibleSticky) {
      setHeaderHeight(headerHeight || 0);
    }

    setVisibleSticky(isVisibleSticky);
  }, [isDesktop]);

  useEffect(() => {
    if (canBeSticky) document.addEventListener('scroll', handleScroll);

    return () => {
      if (canBeSticky) document.removeEventListener('scroll', handleScroll);
    };
  }, [canBeSticky, handleScroll]);

  useEffect(() => {
    // Force recalculation when path or screen changes (example: changing device orientation)
    if (canBeSticky) handleScroll();
  }, [canBeSticky, pathname, handleScroll, isDesktop, isTablet, isMobile]);

  useEffect(() => {
    setDisplayMenu(false);
  }, [pathname]);

  return (
    <div
      className={classnames('header', styles.header)}
      style={headerHeight > 0 ? { minHeight: `${headerHeight}px` } : undefined}
    >
      <div
        className={classnames(styles.headerContainer, {
          [styles.sticky]: isSticky,
        })}
        ref={headerRef}
        id={'headerContainerLayout'}
      >
        <SearchBarProvider>
          <Header
            displayMenu={displayMenu}
            setDisplayMenu={setDisplayMenu}
            isSticky={isSticky}
          />
          <NavigationMenu show={displayMenu} visibleSticky={isSticky} />
        </SearchBarProvider>
      </div>
    </div>
  );
}
