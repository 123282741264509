/* eslint-disable @typescript-eslint/no-unused-vars */
import { PrivilegeValidateResponse } from 'hooks/usePrivilege';
import { createContext } from 'react';
import { Cart } from 'types/Cart';

export const cartContext = createContext({
  cart: undefined as Cart | undefined,
  cartApiError: undefined as Error | undefined,
  privilegeCode: '',
  cartPrivilegeMessage: '',
  isLoaded: false,
  setPrivilegeCode: (privilegeCode: string) => {
    return;
  },
  addToMyBasket: (
    cartContent,
    type?,
    octipasCartContent?,
    productData?,
    openModal?: (state: 'error' | 'addToMyBasket') => void,
    setErrorMessage?
  ): Promise<Cart> => {
    return Promise.resolve({} as Cart);
  },
  updateMyBasket: (
    cartContent,
    openModal?,
    setErrorMessage?
  ): Promise<Cart> => {
    return Promise.resolve({} as Cart);
  },
  deleteProduct: (
    sku,
    reference,
    size,
    type?,
    specialSku?,
    infoOctipas?,
    qty?
  ): Promise<Cart> => {
    return Promise.resolve({} as Cart);
  },
  updateGiftChoice: (giftChoice): Promise<void> => {
    return Promise.resolve();
  },
  validatePrivilegeCode: (
    privilegeCode: string
  ): Promise<PrivilegeValidateResponse> => {
    return Promise.resolve({
      valid_code: false,
      message: [],
    });
  },
  mutateCart: (cart: Cart) => {
    return;
  },
  resetPrivilegeCode: (): void => {
    return;
  },
});
