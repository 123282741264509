import React from 'react';
import classNames from 'classnames';

import styles from './Button.module.scss';

export type ButtonType<T extends React.ElementType = 'button'> = {
  className?: string;
  model?: 'primary' | 'secondary' | 'tertiary' | 'transparent' | 'other';
  fullWidth?: boolean;
  component?: T;
} & Omit<React.ComponentPropsWithoutRef<T>, 'as' | 'ref'>;

export default function Button<T extends React.ElementType = 'button'>({
  children,
  className,
  model = 'transparent',
  fullWidth = false,
  component,
  ...props
}: ButtonType<T>): JSX.Element {
  const Component = component || 'button';

  return (
    <Component
      className={classNames(
        styles.button,
        styles[model],
        className,
        fullWidth && styles.fullWidth
      )}
      {...props}
    >
      {children}
    </Component>
  );
}
