import React, { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { Backdrop } from '@material-ui/core';
import Loader from '../Loader/Loader';

import styles from './Loading.module.scss';

type Props = {
  loading: boolean;

  /**
   * Default: False.
   * False = Move the loading in the body to show in full screen.
   * True = Place the loader under the DOM hierarchy of the parent component.
   */
  disablePortal?: boolean;
  loaderComponent?: ReactNode;
  className?: string;

  /**
   * Default: True.
   * True = The body scrollbar is disabling when the loader is visible.
   */
  disableBodyScrollbar?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

function Loading({
  loading,
  className,
  disablePortal = false,
  loaderComponent = <Loader />,
  disableBodyScrollbar = true,
  ...props
}: Props) {
  function LoadingComponent(): JSX.Element {
    return (
      <Backdrop
        open={loading}
        className={classNames(styles.backdrop, className, {
          [styles.noPortal]: disablePortal,
        })}
        {...props}
      >
        {loaderComponent}
      </Backdrop>
    );
  }

  useEffect(() => {
    document.body.classList[loading && disableBodyScrollbar ? 'add' : 'remove'](
      styles.disableScrollbar
    );

    return () => {
      document.body.classList.remove(styles.disableScrollbar);
    };
  }, [loading, disableBodyScrollbar]);

  return disablePortal ? (
    <LoadingComponent />
  ) : (
    createPortal(<LoadingComponent />, document.body)
  );
}

export default React.memo(Loading);
