export const LS_MODE_HASH = '#GT';
export const PRODUCT_SIZE_ATTRIBUTE = 'codeTaille';
export const PRODUCT_CUP_ATTRIBUTE = 'bonnet';

export const DESCRIPTION_TAB_ID = 0;
export const SIZE_GUIDE_TAB_ID = 1;
export const SHIPPING_TAB_ID = 2;
export const RATING_TAB_ID = 3;
export const RSE_TAB_ID = 4;
export const HELP_TAB_ID = 5;

export const HELP_TAB_CONTENT_HTML = 'fp-besoin-aide.html';
export const SHIPPING_TAB_CONTENT_HTML = 'fp-livraison-retour.html';

export const Morphologies = {
  CURVY: 'C',
  STANDARD: 'S',
} as const;

export const ApiStockType = {
  WEB: 'W',
  DIRECT: 'D',
} as const;
export const PRODUCT_HT_RATIO = 0.8;
