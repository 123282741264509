import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';
import SearchIcon from 'components/Common/Icons/Search';

import styles from './SearchButton.module.scss';

type SearchButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  classes?: {
    button?: string;
    image?: string;
  };
};

function SearchButton({
  classes,
  className,
  ...props
}: SearchButtonProps): JSX.Element {
  return (
    <button
      className={classNames(styles.button, classes?.button, className)}
      type="button"
      {...props}
    >
      <SearchIcon classes={{ root: classNames(styles.icon, classes?.image) }} />
    </button>
  );
}

export default SearchButton;
