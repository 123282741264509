import { useContext, useEffect, useMemo, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

import { userContext } from 'contexts/userContext';
import { cartContext } from 'contexts/cartContext';
import { useCustomer } from 'hooks/useCustomer';
import useEnv from 'hooks/useEnv';
import useHistory from 'hooks/useHistory';
import { getDataLayer } from 'services/gtm/gtmPageView';
import { GTM_CART_PRODUCTS_PAGE } from 'settings/gtm/gtmPageView';
import { formatCartOsG, formatCartProducts } from 'services/gtm/gtmUtils';
import { GtmDataType, GtmOrderDataType } from 'types/gtm/GtmPageView';

export default function useGtmPageView(
  pageType: string,
  title: string,
  data?: GtmDataType,
  callback?: () => void
): void {
  const { pathname } = useLocation();
  const { cart, isLoaded: cartLoaded } = useContext(cartContext);
  const { retarg, gtmUserCookie } = useContext(userContext);
  const { customer, isLoaded: customerLoaded } = useCustomer();
  const { country, env } = useEnv();
  const { isLoaded: historyLoaded, prevState } = useHistory();
  const [dataSent, setDataSent] = useState(false);

  const gtmCart = (data as GtmOrderDataType)?.cart ?? cart;
  const cartProducts = useMemo(() => {
    return (
      gtmCart &&
      GTM_CART_PRODUCTS_PAGE.includes(pageType) && [
        ...formatCartProducts(gtmCart.products),
        ...formatCartOsG(gtmCart.selectedOfferedGifts),
        ...formatCartOsG(gtmCart.selectedSpecialOffers),
      ]
    );
  }, [gtmCart?.products]);

  useEffect(() => {
    try {
      if (
        dataSent ||
        !cartLoaded ||
        retarg === undefined ||
        !customerLoaded ||
        !historyLoaded ||
        data?.isLoaded === false
      ) {
        return;
      }

      const dataLayer = getDataLayer({
        title,
        cart: gtmCart,
        country,
        customer,
        env,
        pageType,
        retarg,
        gtmUserCookie,
        previousUrl: prevState?.location,
        isCartProducts: !!cartProducts,
        ...(cartProducts && { products: cartProducts }),
        ...data,
      });

      TagManager.dataLayer({
        dataLayer,
      });
      setDataSent(true);
      callback?.();
    } catch {
      console.debug('error');
    }
  }, [cartLoaded, retarg, customerLoaded, historyLoaded, data, dataSent]);

  useEffect(() => {
    if (dataSent) {
      setDataSent(false);
    }
  }, [pathname]);
}
