import i18next from 'i18n';
import TagManager from 'react-gtm-module';
import { ProductDetail } from 'types/Controls/ProductSheet';
import { GtmUserCookieType } from 'types/GtmUserCookie';
import { ProductDataDetail, ProductDataListing } from 'types/Product';
import { getDataFromAttributes } from './data';
import {
  isCategoryPage,
  isCheckoutPage,
  isDirectOrderPage,
  isProductSheet,
  isSearchPage,
} from './validation';
import { ReviewsAll } from 'types/Review';
import { OrderConfirmationData } from 'types/Order';
import { ProductVariantsAttributesType } from 'types/ProductPageV2/Products';

export function getDataLayer(dataLayerData: {
  pageName: string;
  event: string;
  eventCat?: string;
  eventAct?: string;
  eventLab?: string;
  eventCommand?: string;
  currencyCode?: string;
  productImpressionListName?: string;
  productList?: GtmProductListType;
  searchKeywords?: string;
  searchResultsNumber?: number;
  promotionList?: GtmPromotionType;
  checkoutStepNumber?: number;
  checkoutStepName?: string;
  orderConfirmationData?: OrderConfirmationData;
}): dataLayerDataType {
  const {
    pageName,
    event,
    eventCat,
    eventAct,
    eventLab,
    eventCommand,
    currencyCode,
    productImpressionListName,
    productList,
    promotionList,
    checkoutStepNumber,
    checkoutStepName,
    orderConfirmationData,
  } = dataLayerData;

  const dataLayer = {
    page_name: pageName,
    page_type: getPageType(pageName),
    event,
    ...(eventCat && { eventCat }),
    ...(eventAct && { eventAct }),
    ...(eventLab && { eventLab }),
  };

  return {
    ...(currencyCode && { currencyCode: currencyCode }),
    ...(checkoutStepNumber && { checkoutStepNumber: checkoutStepNumber }),
    ...(checkoutStepName && { checkoutStepName: checkoutStepName }),
    ...(orderConfirmationData &&
      orderConfirmationData.order_id && {
        order_id: orderConfirmationData.order_id,
      }),
    ...(orderConfirmationData &&
      orderConfirmationData.order_revenue && {
        order_revenue: orderConfirmationData.order_revenue,
      }),
    ...(orderConfirmationData &&
      orderConfirmationData.order_tax && {
        order_tax: orderConfirmationData.order_tax,
      }),
    ...(orderConfirmationData &&
      orderConfirmationData.order_shipping && {
        order_shipping: orderConfirmationData.order_shipping,
      }),
    ...(orderConfirmationData &&
      orderConfirmationData.order_shipping && {
        order_shipping: orderConfirmationData.order_shipping,
      }),
    ...(orderConfirmationData &&
      orderConfirmationData.order_payment_method && {
        order_payment_method: orderConfirmationData.order_payment_method,
      }),
    ...(orderConfirmationData &&
      orderConfirmationData.order_shipping_method && {
        order_shipping_method: orderConfirmationData.order_shipping_method,
      }),
    ...(orderConfirmationData &&
      orderConfirmationData.order_discount_ati && {
        order_discount_ati: orderConfirmationData.order_discount_ati,
      }),
    ...(orderConfirmationData &&
      orderConfirmationData.revenue_tf_without_sf && {
        revenue_tf_without_sf: orderConfirmationData.revenue_tf_without_sf,
      }),
    ...(orderConfirmationData &&
      orderConfirmationData.order_coupon && {
        order_coupon: orderConfirmationData.order_coupon,
      }),
    ...(orderConfirmationData &&
      orderConfirmationData.order_type && {
        order_type: orderConfirmationData.order_type,
      }),
    ...(orderConfirmationData &&
      orderConfirmationData.order_status && {
        order_status: orderConfirmationData.order_status,
      }),
    ...(orderConfirmationData &&
      orderConfirmationData.customer_status && {
        customer_status: orderConfirmationData.customer_status,
      }),
    ...(productImpressionListName && {
      productImpressionListName: productImpressionListName,
    }),
    ...(productList && { productList: productList }),
    ...(promotionList && { promotionList: promotionList }),
    ...(eventCommand && { eventCommand: eventCommand }),
    ...dataLayer,
  };
}

export function getBasicDataLayer(
  pathname: string,
  gtmUserCookie?: GtmUserCookieType,
  searchKeywords?: string,
  searchResultsNumber?: number
): {
  page_env: string;
  langue: string;
  page_type: string;
  page_name: string;
  user_id: string | null;
  user_logged: string;
  user_email_optin: string | null;
  user_status: string;
  user_category: number;
  customer_last_order: string;
  search_keywords?: string;
  search_results_number?: number;
} {
  const basicDataLayer = {
    page_env: process.env.REACT_APP_PAGE_ENV || '',
    langue: process.env.REACT_APP_PAGE_LANGUE || '',
    page_type: getPageType(pathname),
    page_name: pathname,
    user_id: gtmUserCookie ? gtmUserCookie.user_id : null,
    user_logged: gtmUserCookie ? gtmUserCookie.user_logged : 'no',
    user_email_optin: gtmUserCookie ? gtmUserCookie.user_email_optin : null,
    user_status: gtmUserCookie ? gtmUserCookie.user_status : 'propspect',
    user_category: gtmUserCookie ? gtmUserCookie.user_category : 1,
    customer_last_order: gtmUserCookie ? gtmUserCookie.customer_last_order : '',
  };
  if (searchKeywords && searchResultsNumber) {
    return {
      ...basicDataLayer,
      search_keywords: searchKeywords,
      search_results_number: searchResultsNumber,
    };
  }
  return basicDataLayer;
}

/**
 * Get GTM Product
 * @param productDetail
 * @param categories
 * @param productPosition
 * @returns
 */
export function getProduct(
  productDetail:
    | ProductDataListing
    | ProductDataDetail
    | {
        nommodele: string;
        note: string;
        skureelle: string;
        prix: string;
        prixbarre: string;
        metamarque: string;
        couleur: string;
        libelletaille: string;
        disponibilite: string;
        codelibellefamille: string;
        codelibellessfamille: string;
        subuniverse: string;
        universe: string;
        decote: string;
      },
  categories: string[],
  productPosition: number,
  productQuantity?: number,
  productStock?: string,
  productSimplified?: boolean
): GtmProductType {
  const {
    prix: price,
    prixbarre: preDiscountPrice,
    metamarque: metaMarque,
    nommodele: nomModele,
    note: rating,
    skureelle: sku,
    disponibilite: stockFAPI,
    couleur: productColor,
    libelletaille: size,
    codelibellefamille: family,
    codelibellessfamille: subfamily,
    subuniverse,
    universe,
    decote: discountPercentage,
  } = productDetail;
  let isInStock = 'no';
  const stock = productStock ? productStock : stockFAPI;
  const stocksLabel = [
    i18next.t('AVAILABLE').toLocaleLowerCase(),
    i18next.t('LABEL_YES').toLocaleLowerCase(),
  ];
  if (stock && stocksLabel.some(label => stock.includes(label))) {
    isInStock = 'yes';
  }

  const gtmProduct = {
    product_name: nomModele,
    product_id: sku?.slice(0, 4),
    product_unitprice_ati: preDiscountPrice !== '0' ? preDiscountPrice : price,
    product_discount_ati: price,
    promo_discount: preDiscountPrice !== '0' ? `${discountPercentage}%` : null,
    product_brand: metaMarque ? metaMarque : 'afibel',
    product_color: productColor,
    product_size: size,
    product_instock: isInStock,
    product_rating: rating || '',
    product_category1: family,
    product_category2: subfamily,
    product_category3: subuniverse,
    product_category4: universe,
    productImpressionListPosition: productPosition,
    product_quantity: productQuantity,
  };

  if (productSimplified) {
    const {
      product_discount_ati, // eslint-disable-line @typescript-eslint/no-unused-vars
      promo_discount, // eslint-disable-line @typescript-eslint/no-unused-vars
      product_brand, // eslint-disable-line @typescript-eslint/no-unused-vars
      product_color, // eslint-disable-line @typescript-eslint/no-unused-vars
      product_size, // eslint-disable-line @typescript-eslint/no-unused-vars
      product_rating, // eslint-disable-line @typescript-eslint/no-unused-vars
      product_instock, // eslint-disable-line @typescript-eslint/no-unused-vars
      productImpressionListPosition, // eslint-disable-line @typescript-eslint/no-unused-vars
      ...gtmProductSimplified
    } = gtmProduct;

    return gtmProductSimplified;
  }

  return gtmProduct;
}

/**
 * Get GTM Products list
 * @param products
 * @param productsMerchActions
 * @param productsCategories
 * @param attributes
 * @returns
 */
export function getProductList(
  products: {
    topProducts: ProductDetail[];
    products: ProductDetail[][];
    averageRatings?: ReviewsAll;
  },
  productsMerchActions: productsMerchActionsType,
  productsCategories: string[],
  attributes: {
    [key: string]: {
      Label: string;
      MainName: string;
    };
  }
): GtmProductListType | undefined {
  const { products: productsList, topProducts, averageRatings } = products;
  let productPosition = 1;

  if (!productsList) {
    return undefined;
  }

  const gtmTopProducts = topProducts.map(product => {
    const productData = getDataFromAttributes(
      product.Attributes,
      attributes
    ) as ProductDataListing;
    const { skureelle: sku } = productData;
    const rating =
      averageRatings && averageRatings[sku.slice(0, 4)]
        ? averageRatings[sku.slice(0, 4)]?.rate
        : '';
    const gtmTopProduct = getProduct(
      { ...productData, note: rating },
      productsCategories,
      productPosition
    );
    productPosition++;
    return gtmTopProduct;
  });

  const gtmProductList = productsList.map(productsByPage => {
    return productsByPage.flatMap((product, index) => {
      let gtmMerchProduct = null as GtmProductType | null;
      const {
        PLTemplate2ProduitGrand1Product,
        PLTemplate2ProduitGrand2Product,
        PLTemplate2ProduitGrand3Product,
      } = productsMerchActions;
      if (
        PLTemplate2ProduitGrand1Product &&
        index + 1 === PLTemplate2ProduitGrand1Product?.position
      ) {
        const productData = getDataFromAttributes(
          PLTemplate2ProduitGrand1Product.product?.Attributes,
          attributes
        ) as ProductDataListing;
        const { skureelle: sku } = productData;
        const rating =
          averageRatings && averageRatings[sku.slice(0, 4)]
            ? averageRatings[sku.slice(0, 4)]?.rate
            : '';
        gtmMerchProduct = getProduct(
          { ...productData, note: rating },
          productsCategories,
          productPosition
        );
        productPosition++;
      }
      if (
        PLTemplate2ProduitGrand2Product &&
        index + 1 === PLTemplate2ProduitGrand2Product?.position
      ) {
        const productData = getDataFromAttributes(
          PLTemplate2ProduitGrand2Product.product?.Attributes,
          attributes
        ) as ProductDataListing;
        const { skureelle: sku } = productData;
        const rating =
          averageRatings && averageRatings[sku.slice(0, 4)]
            ? averageRatings[sku.slice(0, 4)]?.rate
            : '';
        gtmMerchProduct = getProduct(
          { ...productData, note: rating },
          productsCategories,
          productPosition
        );
        productPosition++;
      }
      if (
        PLTemplate2ProduitGrand3Product &&
        index + 1 === PLTemplate2ProduitGrand3Product?.position
      ) {
        const productData = getDataFromAttributes(
          PLTemplate2ProduitGrand3Product.product?.Attributes,
          attributes
        ) as ProductDataListing;
        const { skureelle: sku } = productData;
        const rating =
          averageRatings && averageRatings[sku.slice(0, 4)]
            ? averageRatings[sku.slice(0, 4)]?.rate
            : '';
        gtmMerchProduct = getProduct(
          { ...productData, note: rating },
          productsCategories,
          productPosition
        );
        productPosition++;
      }
      const productData = getDataFromAttributes(
        product.Attributes,
        attributes
      ) as ProductDataListing;
      const { skureelle: sku } = productData;
      const rating =
        averageRatings && averageRatings[sku.slice(0, 4)]
          ? averageRatings[sku.slice(0, 4)]?.rate
          : '';
      const gtmProduct = getProduct(
        { ...productData, note: rating },
        productsCategories,
        productPosition
      );
      productPosition++;
      return gtmMerchProduct ? [gtmMerchProduct, gtmProduct] : gtmProduct;
    });
  });

  return [gtmTopProducts, gtmProductList].flat(
    gtmProductList.length + 1
  ) as GtmProductListType;
}

export function getFilteredProductList(
  gtmProductList: GtmProductListType | undefined
): GtmProductListType | undefined {
  const filteredList = gtmProductList
    ? gtmProductList.slice(0, 3).map(product => {
        return { product_id: product.product_id };
      })
    : undefined;
  return filteredList;
}

export function getProductDetailV2(
  productInfo: ProductVariantsAttributesType
): {
  nommodele: string;
  note: string;
  skureelle: string;
  prix: string;
  prixbarre: string;
  metamarque: string;
  couleur: string;
  libelletaille: string;
  disponibilite: string;
  codelibellefamille: string;
  codelibellessfamille: string;
  subuniverse: string;
  universe: string;
  decote: string;
} {
  return {
    nommodele: productInfo.nomModele.Value,
    note: productInfo.noteProduit.Value,
    skureelle: productInfo.SKUReelle.Value,
    prix: productInfo.prix.Value,
    prixbarre: productInfo.prixBarre.Value,
    metamarque: productInfo.metaMarque.Value,
    couleur: productInfo.couleur.Value,
    libelletaille: productInfo.libelleTaille.Value,
    disponibilite: productInfo.disponibilite.Value,
    codelibellefamille: productInfo.codeLibelleFamille.Value,
    codelibellessfamille:
      productInfo?.codeLibelleSsfamille?.Value ||
      productInfo.codeFamilleSsfamille.Value,
    subuniverse: productInfo.subuniverse.Value,
    universe: productInfo.universe.Value,
    decote: productInfo.decote.Value,
  };
}

export function gtmEventSantianoEcommerce(
  pathname: string,
  eventCommand: string,
  productImpressionListName: string,
  productList: GtmProductListType | undefined,
  gtmUserCookie?: GtmUserCookieType,
  searchKeywords?: string,
  searchResultsNumber?: number,
  checkoutStepNumber?: number,
  checkoutStepName?: string,
  orderConfirmationData?: OrderConfirmationData
): void {
  const data = {
    pageName: pathname,
    event: 'santianoEcommerce',
    eventCommand,
    currencyCode: process.env.REACT_APP_CURRENCY_FUNDS_CODE,
    productImpressionListName,
    productList,
    searchKeywords,
    searchResultsNumber,
    checkoutStepNumber,
    checkoutStepName,
    orderConfirmationData,
  };
  TagManager.dataLayer({
    dataLayer: getDataLayer(
      searchKeywords && searchResultsNumber ? data : data
    ),
  });
}

export function onClickOrLoadPromo(
  promotionDetail: GtmPromotionType,
  gtmUserCookie?: GtmUserCookieType
): void {
  const {
    pageName,
    eventCommand,
    promotionName,
    promotionId,
    promotionCreative,
    promotionPosition,
  } = promotionDetail;
  TagManager.dataLayer({
    dataLayer: getDataLayer({
      pageName: pageName || '/',
      event: 'santianoEcommerce',
      eventCommand,
      promotionList: {
        promotionName,
        promotionId,
        promotionCreative,
        promotionPosition,
      },
    }),
    gtmUserCookie,
  });
}

export function gtmAfiEventLab(
  pathname: string,
  event: string,
  eventCat: string,
  eventAct: string,
  gtmUserCookie?: GtmUserCookieType,
  eventLab?: string
): void {
  const data = {
    pageName: pathname,
    event,
    eventCat: eventCat,
    eventAct,
    eventLab,
  };
  TagManager.dataLayer({
    dataLayer: getDataLayer(eventLab ? data : data),
  });
}

function getPageType(url: string): string {
  if (isProductSheet(url)) {
    return 'product';
  }
  if (isCategoryPage(url)) {
    return 'productList';
  }
  if (isSearchPage(url)) {
    return 'searchPage';
  }
  if (isCheckoutPage(url)) {
    return 'funnel';
  }
  if (isDirectOrderPage(url)) {
    return 'funnel';
  }
  return 'homepage';
}

type dataLayerDataType = {
  event: string;
  eventCat?: string;
  eventAct?: string;
  eventLab?: string;
  eventCommand?: string;
  currencyCode?: string;
};

type GtmProductType = {
  product_name: string;
  product_id: string;
  product_unitprice_ati: string;
  product_discount_ati?: string;
  promo_discount?: string | null;
  product_brand?: string;
  product_color?: string | null;
  product_size?: string | null;
  product_rating?: string;
  product_instock?: string;
  product_category1: string | null;
  product_category2: string | null;
  product_category3: string | null;
  product_category4: string | null;
  product_quantity?: number | null;
  productImpressionListPosition?: number;
};

type GtmFilteredProductType = {
  product_id: string;
};

export type GtmProductListType = GtmProductType[] | GtmFilteredProductType[];

type GtmPromotionType = {
  pageName?: string;
  eventCommand?: string;
  promotionName?: string;
  promotionId?: string;
  promotionCreative?: string;
  promotionPosition?: string;
};

type productsMerchActionsType = {
  PLTemplate2ProduitGrand1Product?: {
    product?: ProductDetail;
    position: number;
  } | null;
  PLTemplate2ProduitGrand2Product?: {
    product?: ProductDetail;
    position: number;
  } | null;
  PLTemplate2ProduitGrand3Product?: {
    product?: ProductDetail;
    position: number;
  } | null;
};
