import React, { PropsWithChildren } from 'react';

import HeaderLayout from 'layout/HeaderLayout';
import ScrollToTopButton from 'components/Common/ScrollToTopButton/ScrollToTopButton';
import Ariane from 'components/Ariane/Ariane';
import BREADCRUMB_PATHS from 'components/Ariane/arianeConfig';

type Props = {
  emptyLayout?: boolean;
  arianeCurrentPath?: string;
};

const AfibelFooter = React.lazy(
  () => import('../components/AfibelFooter/AfibelFooter')
);

export default function MainLayout({
  children,
  emptyLayout = false,
  arianeCurrentPath,
}: PropsWithChildren<Props>): JSX.Element {
  const breadcrumb = arianeCurrentPath && BREADCRUMB_PATHS[arianeCurrentPath];

  return (
    <>
      <div>
        {!emptyLayout && <HeaderLayout />}
        {breadcrumb && (
          <Ariane items={breadcrumb.items} style={breadcrumb.style} />
        )}
        <>{children}</>
        <ScrollToTopButton />
      </div>
      <AfibelFooter />
    </>
  );
}
