import {
  Category as CategoryType,
  PL_Template1Banniere,
  PL_Template1ProduitTop,
  PL_Template1Slot1,
  PL_Template1Slot2,
  PL_Template1Slot3,
  PL_Template2ProduitGrand1,
  PL_Template2ProduitGrand2,
  PL_Template2ProduitGrand3,
} from 'types/Category';
import { ProductBreadcrumbType } from 'types/ProductPageV2/Products';
import { ProductDetail } from 'types/Controls/ProductSheet';
import { Hierarchy } from 'types/Hierarchy';
import { Product, ProductDataDetail } from 'types/Product';
import { formatMainName } from './formatter';
import { getUri } from './generic';

type AttributeDefinitionType = {
  AttributeDefinitionCode: string;
  Value: string;
  AttributeGlossaryId?: number | null;
  Metadata: {
    Label: string;
    Value: string;
  }[];
};

export function dataCatagoryFormatter(
  data?: CategoryType
): {
  BreadCrumb?: CategoryType['Zones'][0]['Controls'][0];
  HierarchyListing?: CategoryType['Zones'][0]['Controls'][1];
  NextFilters?: CategoryType['Zones'][0]['Controls'][2];
  Sorting?: CategoryType['Zones'][0]['Controls'][3];
  PageSizer?: CategoryType['Zones'][0]['Controls'][4];
  HierarchyChildren?: CategoryType['Zones'][0]['Controls'][5];
  PLTemplate1Banniere?: PL_Template1Banniere['Controls'][0];
  PLTemplate1ProduitTop?: PL_Template1ProduitTop['Controls'][0];
  PLTemplate1Slot1?: PL_Template1Slot1['Controls'][0];
  PLTemplate1Slot2?: PL_Template1Slot2['Controls'][0];
  PLTemplate1Slot3?: PL_Template1Slot3['Controls'][0];
  PLTemplate2ProduitGrand1?: PL_Template2ProduitGrand1['Controls'][0];
  PLTemplate2ProduitGrand2?: PL_Template2ProduitGrand2['Controls'][0];
  PLTemplate2ProduitGrand3?: PL_Template2ProduitGrand3['Controls'][0];
} {
  if (!data) {
    return {};
  }
  return {
    BreadCrumb: data?.Zones[0]?.Controls[0],
    HierarchyListing: data?.Zones[0]?.Controls[1],
    NextFilters: data?.Zones[0]?.Controls[2],
    Sorting: data?.Zones[0]?.Controls[3],
    PageSizer: data?.Zones[0]?.Controls[4],
    HierarchyChildren: data?.Zones[0]?.Controls[5],
    PLTemplate1Banniere:
      data?.Zones[1]?.Name === 'PL_Template1Banniere'
        ? ((data?.Zones[1]
            ?.Controls[0] as unknown) as PL_Template1Banniere['Controls'][0])
        : undefined,
    PLTemplate1ProduitTop:
      data?.Zones[2]?.Name === 'PL_Template1ProduitTop'
        ? ((data?.Zones[2]
            ?.Controls[0] as unknown) as PL_Template1ProduitTop['Controls'][0])
        : undefined,
    PLTemplate1Slot1:
      data?.Zones[3]?.Name === 'PL_Template1Slot1'
        ? ((data?.Zones[3]
            ?.Controls[0] as unknown) as PL_Template1Slot1['Controls'][0])
        : undefined,
    PLTemplate1Slot2:
      data?.Zones[4]?.Name === 'PL_Template1Slot2'
        ? ((data?.Zones[4]
            ?.Controls[0] as unknown) as PL_Template1Slot2['Controls'][0])
        : undefined,
    PLTemplate1Slot3:
      data?.Zones[5]?.Name === 'PL_Template1Slot3'
        ? ((data?.Zones[5]
            ?.Controls[0] as unknown) as PL_Template1Slot3['Controls'][0])
        : undefined,
    PLTemplate2ProduitGrand1:
      data?.Zones[2]?.Name === 'PL_Template2ProduitGrand1'
        ? ((data?.Zones[2]
            ?.Controls[0] as unknown) as PL_Template2ProduitGrand1['Controls'][0])
        : undefined,
    PLTemplate2ProduitGrand2:
      data?.Zones[3]?.Name === 'PL_Template2ProduitGrand2'
        ? ((data?.Zones[3]
            ?.Controls[0] as unknown) as PL_Template2ProduitGrand2['Controls'][0])
        : undefined,
    PLTemplate2ProduitGrand3:
      data?.Zones[4]?.Name === 'PL_Template2ProduitGrand3'
        ? ((data?.Zones[4]
            ?.Controls[0] as unknown) as PL_Template2ProduitGrand3['Controls'][0])
        : undefined,
  };
}

export function dataProductSheetFormatter(
  data?: Product
): {
  BreadCrumb: Product['Zones'][0]['Controls'][0]['Hierarchies'];
  ProductAttributes?: Product['Zones'][0]['Controls'][1]['Product']['Attributes'];
  Skus?: Product['Zones'][0]['Controls'][2]['Skus'];
  FPReassuranceBanniere?: Product['Zones'][1]['Controls'][0]['Actions'][0]['HtmlContent'];
  RelatedProductMerchandisingActions?: Product['Zones'][2]['Controls'][0]['Actions'];
  SimilarProductMerchandisingActions?: Product['Zones'][3]['Controls'][0]['Actions'];
  TotalLookMerchandisingActions?: Product['Zones'][4]['Controls'][0]['Actions'];
} {
  return {
    BreadCrumb: data?.Zones[0]?.Controls[0]?.Hierarchies || [],
    ProductAttributes: data?.Zones[0]?.Controls[1].Product?.Attributes,
    Skus: data?.Zones[0]?.Controls[2]?.Skus,
    FPReassuranceBanniere: data?.Zones[1]?.Controls[0]?.Actions[0]?.HtmlContent,
    RelatedProductMerchandisingActions: data?.Zones[2]?.Controls[0]?.Actions,
    SimilarProductMerchandisingActions: data?.Zones[3]?.Controls[0]?.Actions,
    TotalLookMerchandisingActions: data?.Zones[4]?.Controls[0]?.Actions,
  };
}

export function newHierarchy(url: string, label: string): Hierarchy {
  return {
    Description: '',
    Id: 0,
    Label: label,
    Metadata: [{ Label: '', Value: '' }],
    Url: url,
    __type: '',
    Children: [],
  };
}

export function newProductBreadCrumb(
  url: string,
  label: string
): ProductBreadcrumbType {
  return {
    Description: '',
    Id: 0,
    Label: label,
    Metadata: [],
    Url: url,
    __type: '',
    Children: [],
  };
}

export function getDataFromAttributes(
  dataAttributes:
    | {
        AttributeDefinitionCode: string;
        Value: string;
        AttributeGlossaryId?: number | null;
      }[]
    | undefined,
  generalAttributes: { [key: string]: { Label: string; MainName: string } }
): { [mainName: string]: string } {
  if (!dataAttributes || !Object.keys(generalAttributes).length) {
    return {};
  }
  return dataAttributes.reduce(
    (accumulator, attribute) => ({
      ...accumulator,
      [formatMainName(
        generalAttributes[attribute.AttributeDefinitionCode]?.MainName ??
          getSysName(generalAttributes[attribute.AttributeDefinitionCode])
      )]: attribute.Value,
    }),
    {}
  );
}

export function getSkuUrl(
  skus: ProductDetail[],
  productSku: string,
  attributes: { [key: string]: { Label: string; MainName: string } }
): string {
  return (
    getUri(
      skus?.find(sku => {
        const skuData = getDataFromAttributes(
          sku.Attributes,
          attributes
        ) as ProductDataDetail;
        return skuData.skureelle === productSku;
      })?.Url
    ) || ''
  );
}

function getSysName(attributes?: AttributeDefinitionType): string | undefined {
  const { Metadata } = { ...attributes };
  return Metadata?.find(item => item.Label === 'SysName')?.Value;
}
