import React from 'react';

import MenuItem, { MenuItemClasses } from './MenuItem';
import { MenuItemType } from 'types/menu/Menu';

import styles from './MenuMobile.module.scss';

type MenuItemsProps = {
  goTo: (item: MenuItemType) => void;
  items: MenuItemType[];
  itemPrefixId: string;
  onClickItem: (currentLabel: string) => void;
  classes?: {
    item?: MenuItemClasses;
  };
};

export default function MenuItems({
  goTo,
  items,
  itemPrefixId,
  onClickItem,
  classes,
}: MenuItemsProps): JSX.Element {
  return (
    <ul className={styles.listing}>
      {items.map(item => {
        if (item.metadata.menuMobNoLayer) {
          return null;
        }

        return (
          <li key={item.id}>
            <MenuItem
              item={item}
              goTo={goTo}
              classes={classes?.item}
              itemPrefixId={itemPrefixId}
              onClick={onClickItem}
            />
          </li>
        );
      })}
    </ul>
  );
}
