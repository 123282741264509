import { createContext, Dispatch, RefObject, SetStateAction } from 'react';

import { SearchBarRefType } from 'components/SearchBar/SearchBar';

export type SearchBarContextType = {
  searchBarRef: RefObject<SearchBarRefType | null>;
  searchBarDesktopRef: RefObject<SearchBarRefType | null>;
  searchKeyword: string;
  setSearchKeyword: Dispatch<SetStateAction<string>>;
};

const SearchBarContext = createContext<SearchBarContextType | undefined>(
  undefined
);

export default SearchBarContext;
