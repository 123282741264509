import 'intl-pluralrules';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';

import tradEN from 'translations/en/en-GB.json';
import tradFR from 'translations/fr/fr-FR.json';
import tradCheckoutEN from 'translations/en/checkout-en-GB.json';
import tradCheckoutFR from 'translations/fr/checkout-fr-FR.json';
import tradContactPageEN from 'translations/en/contact-page-en-GB.json';
import tradContactPageFR from 'translations/fr/contact-page-fr-FR.json';
import tradDirectOrderPageEN from 'translations/en/direct-order-page-en-GB.json';
import tradDirectOrderPageFR from 'translations/fr/direct-order-page-fr-FR.json';
import tradNewsletterPageEN from 'translations/en/newsletter-page-en-GB.json';
import tradNewsletterPageFR from 'translations/fr/newsletter-page-fr-FR.json';
import tradNewsLetterSubPageEN from 'translations/en/Account/newsletters-subscription-page-en-GB.json';
import tradNewsLetterSubPageFR from 'translations/fr/Account/newsletters-subscription-page-fr-FR.json';
import tradDeliveryAddressEN from 'translations/en/Account/delivery-address-page-en-GB.json';
import tradDeliveryAddressFR from 'translations/fr/Account/delivery-address-page-fr-FR.json';
import tradSignUpPageEN from 'translations/en/signup-page-en-GB.json';
import tradSignUpPageFR from 'translations/fr/signup-page-fr-FR.json';
import tradSummaryPageEN from 'translations/en/Account/summary-page-en-GB.json';
import tradSummaryPageFR from 'translations/fr/Account/summary-page-fr-FR.json';
import tradPersonalDetailsPageEN from 'translations/en/Account/personal-details-page-en-GB';
import tradPersonalDetailsPageFR from 'translations/fr/Account/personal-details-page-fr-FR';
import tradProductPageEN from 'translations/en/product-page-en-GB';
import tradProductPageFR from 'translations/fr/product-page-fr-FR';
import tradCatalogRequestPageEN from 'translations/en/Account/catalog-request-page-en-GB';
import tradCatalogRequestPageFR from 'translations/fr/Account/catalog-request-page-fr-FR';
import tradOrdersPageEN from 'translations/en/Account/orders-page-en-GB.json';
import tradOrdersPageFR from 'translations/fr/Account/orders-page-fr-FR';
import resetPasswordEN from 'translations/en/Account/reset-password-en-GB.json';
import resetPasswordFR from 'translations/fr/Account/reset-password-fr-FR.json';
import reviewEN from 'translations/en/review-en-GB.json';
import reviewFR from 'translations/fr/review-fr-FR.json';
import sitePlanEN from 'translations/en/site-plan-en-GB.json';
import sitePlanFR from 'translations/fr/site-plan-fr-FR.json';
import listingPageFR from 'translations/fr/listing-page-fr-FR.json';
import listingPageEN from 'translations/en/listing-page-en-GB.json';
import loginPageFR from 'translations/fr/Account/login-page-fr-FR.json';
import loginPageEN from 'translations/en/Account/login-page-en-GB.json';

i18n.use(initReactI18next).init({
  interpolation: {
    escapeValue: false,
    format: (value, format) => {
      const date = value.split('/');
      const newDate = date[1] + '/' + date[0] + '/' + date[2];

      return moment(newDate).format(format);
    },
  },
  fallbackLng: 'en',
  lng: process.env.REACT_APP_PAGE_LANGUE,
  debug: true,
  keySeparator: '.',
  resources: {
    en: {
      translation: tradEN,
      checkout: tradCheckoutEN,
      contactPage: tradContactPageEN,
      directOrderPage: tradDirectOrderPageEN,
      newsletterPage: tradNewsletterPageEN,
      newslettersSubPage: tradNewsLetterSubPageEN,
      deliveryAddressPage: tradDeliveryAddressEN,
      signUpPage: tradSignUpPageEN,
      summaryPage: tradSummaryPageEN,
      personalDetailsPage: tradPersonalDetailsPageEN,
      productPage: tradProductPageEN,
      catalogRequestPage: tradCatalogRequestPageEN,
      ordersPage: tradOrdersPageEN,
      resetPassword: resetPasswordEN,
      review: reviewEN,
      sitePlan: sitePlanEN,
      listingPage: listingPageEN,
      loginPage: loginPageEN,
    },
    fr: {
      translation: tradFR,
      checkout: tradCheckoutFR,
      contactPage: tradContactPageFR,
      directOrderPage: tradDirectOrderPageFR,
      newsletterPage: tradNewsletterPageFR,
      newslettersSubPage: tradNewsLetterSubPageFR,
      deliveryAddressPage: tradDeliveryAddressFR,
      signUpPage: tradSignUpPageFR,
      summaryPage: tradSummaryPageFR,
      personalDetailsPage: tradPersonalDetailsPageFR,
      productPage: tradProductPageFR,
      catalogRequestPage: tradCatalogRequestPageFR,
      ordersPage: tradOrdersPageFR,
      resetPassword: resetPasswordFR,
      review: reviewFR,
      sitePlan: sitePlanFR,
      listingPage: listingPageFR,
      loginPage: loginPageFR,
    },
  },
});

export default i18n;
