import React, { useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import MobileNavigationToolbar from './MobileNavigationToolbar';
import NavigationMenuMobile from './NavigationMenuMobile';

import styles from './MobileNavigationDrawer.module.scss';

type Props = {
  isDrawerOpen: boolean;
};

export default function MobileNavigationDrawer({
  isDrawerOpen,
}: Props): JSX.Element {
  const idSearchBarContainer = 'header-search';
  const el = useRef<HTMLDivElement>(null);
  const updateTopPosition = useCallback(() => {
    if (idSearchBarContainer) {
      const searchTopValue = Math.max(
        0,
        document.getElementById(idSearchBarContainer)?.getBoundingClientRect()
          .top ?? 0
      );

      if (el.current) {
        el.current.style.top = `${searchTopValue}px`;
      }
    }
  }, []);

  useEffect(() => {
    document.body.classList[isDrawerOpen ? 'add' : 'remove'](styles.drawerOpen);
    document.addEventListener('scroll', updateTopPosition);
    window.addEventListener('resize', updateTopPosition);
    updateTopPosition();

    return () => {
      document.body.classList.remove(styles.drawerOpen);
      document.removeEventListener('scroll', updateTopPosition);
      window.removeEventListener('resize', updateTopPosition);
    };
  }, [isDrawerOpen]);

  return createPortal(
    <div
      ref={el}
      className={classNames(styles.mobileNavigationDrawer, {
        [styles.open]: isDrawerOpen,
      })}
    >
      <NavigationMenuMobile
        classes={{ container: styles.navigation }}
        isVisible={isDrawerOpen}
      />
      <MobileNavigationToolbar classes={{ container: styles.toolbar }} />
    </div>,
    document.body
  );
}
