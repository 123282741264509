import React, {
  ForwardedRef,
  forwardRef,
  MouseEvent,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import AutoCompletion, {
  CloseTypes,
  CloseTypesKeys,
} from './AutoCompletion/AutoCompletion';
import MerchBanners from './MerchBanners/MerchBanners';
import SearchBarInputField from './SearchBarForm/SearchBarInputField';
import Loading from 'components/Common/Loading/Loading';
import Button from 'components/UI/Buttons/Button/Button';
import useAcp from 'hooks/useAcp';
import { useSearchBar } from 'hooks/useSearchBar';
import { getUri } from 'services/generic';
import search, { extractCatalogRef } from 'services/search';
import { MOBILE_AND_TABLET } from 'settings/mediaQuery';

import SearchIcon from 'assets/img/search-icon.svg';
import PictoClose from 'assets/img/headerV2/close.svg';

import styles from './SearchBar.module.scss';

export const OnCloseType = {
  ...CloseTypes,
} as const;

export type OnCloseTypeKeys = typeof OnCloseType[keyof typeof OnCloseType];

type PropsType = {
  id?: string;
  sticky?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onClickAway?: () => void;
  onClose?: (type?: OnCloseTypeKeys) => void;
  onRedirect?: () => void;
  classes?: {
    container?: string;
    inputGroup?: string;
  };
  enableAutocompleteFromLimit?: number;
  placementId: string;
};

export type SearchBarRefType = {
  focusSearchInput: () => void;
  closeMerch: () => void;
  clearSearchInput: () => void;
};

/**
 * Input text to search for a product
 */
function SearchBar(
  {
    id,
    sticky,
    onFocus,
    onBlur,
    onClickAway,
    onClose,
    onRedirect,
    classes,
    enableAutocompleteFromLimit = 2,
    placementId,
  }: PropsType,
  ref?: ForwardedRef<SearchBarRefType>
): JSX.Element {
  const { searchKeyword, setSearchKeyword } = useSearchBar();
  const isMobileAndTablet = useMediaQuery({ query: MOBILE_AND_TABLET });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [isMerchVisible, setIsMerchVisible] = useState<boolean>(false);
  const searchKeywordValue = extractCatalogRef(searchKeyword) || searchKeyword;
  const { acpData } = useAcp(searchKeywordValue);

  const isAutoCompleteVisible =
    searchKeyword.length >= enableAutocompleteFromLimit;

  const isCloseButtonVisible =
    isMerchVisible || isFocus || searchKeyword.length > 0;

  const refFunctions = () => {
    return {
      focusSearchInput: () => {
        searchInputRef.current?.focus();
      },
      closeMerch: () => {
        setIsMerchVisible(false);
      },
      clearSearchInput: () => {
        setSearchKeyword('');
      },
    };
  };

  useImperativeHandle(ref, refFunctions, []);

  function onFocusInput() {
    setIsFocus(true);
    onFocus && onFocus();
  }

  function onBlurInput() {
    setIsFocus(false);
    onBlur && !searchKeyword && onBlur();
    onClose && !searchKeyword && onClose();
  }

  function onClickOutside() {
    setIsFocus(false);
    onClickAway?.();
  }

  function handleRedirect() {
    if (searchKeywordValue) {
      setIsLoading(true);
      search(searchKeywordValue).then(({ searchApiData, redirectUrl }) => {
        setIsLoading(false);
        onRedirect && onRedirect();
        closeHandler();
        navigate(getUri(redirectUrl), {
          state: {
            searchApiData,
            acpData,
          },
        });
      });
    }
  }

  function closeHandler(type?: CloseTypesKeys) {
    setIsMerchVisible(false);
    searchInputRef.current?.blur();
    onClose && onClose(type as OnCloseTypeKeys);
  }

  function closeSearch(e: MouseEvent) {
    e.preventDefault();
    closeHandler();
  }

  function onKeyPress(ev: React.KeyboardEvent<HTMLInputElement>) {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      ev.stopPropagation();
      handleRedirect();
    }
  }

  useEffect(() => {
    if (
      isMobileAndTablet &&
      isFocus &&
      searchKeyword.length < enableAutocompleteFromLimit
    ) {
      setIsMerchVisible(true);
    }

    return () => {
      if (
        !isMobileAndTablet ||
        searchKeyword.length >= enableAutocompleteFromLimit
      ) {
        setIsMerchVisible(false);
      }
    };
  }, [isFocus, isMobileAndTablet, searchKeyword]);

  return (
    <ClickAwayListener onClickAway={onClickOutside} mouseEvent={'onMouseDown'}>
      <div
        id={id}
        className={classNames(styles.container, classes?.container, {
          [styles.sticky]: sticky,
        })}
      >
        <form>
          <div
            className={classNames(styles.inputContainer, classes?.inputGroup)}
          >
            <div className={styles.searchIconWrapper}>
              <img
                src={SearchIcon}
                className={styles.searchIcon}
                alt="search"
                height="21"
                width="23"
              />
            </div>
            <SearchBarInputField
              ref={searchInputRef}
              onChange={ev => setSearchKeyword(ev.target.value)}
              onKeyPress={onKeyPress}
              value={searchKeyword}
              onFocus={onFocusInput}
              onBlur={onBlurInput}
            />
            {isCloseButtonVisible && <CloseButton closeSearch={closeSearch} />}
            {isMobileAndTablet && searchKeyword && (
              <Button
                className={styles.searchButton}
                onClick={(ev: MouseEvent) => {
                  ev.preventDefault();
                  handleRedirect();
                }}
                aria-label={t('LABEL_FIND_A_PRODUCT')}
              >
                {t('LABEL_OK')}
              </Button>
            )}
          </div>
          {isCloseButtonVisible && (
            <CloseButton closeSearch={closeSearch} isCloseImage />
          )}
        </form>
        {isAutoCompleteVisible && (
          <AutoCompletion
            data={acpData}
            onClose={closeHandler}
            placementId={placementId}
            onClickSeeAll={ev => {
              ev.preventDefault();
              handleRedirect();
            }}
          />
        )}
        {isMerchVisible && (
          <MerchBanners
            portalElement={
              document.getElementById('headerContainerLayout') || undefined
            }
          />
        )}
        {isLoading && <Loading loading />}
      </div>
    </ClickAwayListener>
  );
}

export default forwardRef(SearchBar);

function CloseButton({
  closeSearch,
  isCloseImage = false,
}: {
  closeSearch: (e: MouseEvent) => void;
  isCloseImage?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.close}>
      <button onClick={closeSearch} aria-label={t('LABEL_CLOSE')}>
        {isCloseImage && <img src={PictoClose} alt="icon-close-menu" />}
        {!isCloseImage && <CloseIcon />}
      </button>
    </div>
  );
}
