import { useContext } from 'react';

import SearchBarContext, {
  SearchBarContextType,
} from 'contexts/searchBarContext';

export function useSearchBar(): SearchBarContextType {
  const context = useContext(SearchBarContext);
  if (!context) {
    throw new Error('useHeader must be used within a HeaderProvider');
  }

  return context;
}
