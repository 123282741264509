import React, { MutableRefObject, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import HeaderBanner from './HeaderBanner';
import RightNavigationLinks from './RightNavigationMenu/RightNavigationMenu';
import SearchButton from './SearchButton/SearchButton';
import Logo from 'components/Common/Logo/Logo';
import Button from 'components/UI/Buttons/Button/Button';
import CloseIcon from 'components/Common/Icons/Close';
import BurgerMenu from 'components/Common/Icons/BurgerMenu';
import SearchBar, {
  OnCloseType,
  OnCloseTypeKeys,
  SearchBarRefType,
} from 'components/SearchBar/SearchBar';
import { cartContext } from 'contexts/cartContext';
import { useSearchBar } from 'hooks/useSearchBar';
import { LARGE_LAPTOP_AND_DESKTOP } from 'settings/mediaQuery';

import styles from './Header.module.scss';

type Props = {
  /** If megaMenu is open */
  displayMenu: boolean;
  /** Function to update current displayMenu value */
  setDisplayMenu: (displayMenu: boolean) => void;
  isSticky: boolean;
};

export default function Header({
  displayMenu,
  setDisplayMenu,
  isSticky,
}: Props): JSX.Element {
  const { cart } = useContext(cartContext);
  const isLargeLaptop = useMediaQuery({ query: LARGE_LAPTOP_AND_DESKTOP });
  const nbProducts = cart?.nbProducts || 0;

  return (
    <div className={styles.headerContainer} id="header-container">
      <HeaderBanner />
      <div
        id="header-block"
        className={classNames(styles.header, {
          [styles.fullCart]: nbProducts > 0,
          [styles.sticky]: isSticky,
        })}
      >
        <LeftBlock displayMenu={displayMenu} setDisplayMenu={setDisplayMenu} />
        <Logo classes={{ container: styles.logo }} />
        <RightBlock
          displayMenu={displayMenu}
          isLargeLaptop={isLargeLaptop}
          setDisplayMenu={setDisplayMenu}
        />
        <SearchBarBlock isSticky={isSticky} />
      </div>
    </div>
  );
}

function LeftBlock({
  displayMenu,
  setDisplayMenu,
}: {
  displayMenu: boolean;
  setDisplayMenu: (displayMenu: boolean) => void;
}) {
  const { searchBarDesktopRef } = useSearchBar();
  const openMenuClickHandler = useCallback(() => {
    setDisplayMenu(true);
    searchBarDesktopRef.current?.closeMerch();
  }, []);

  const showSearchBarBtnHandler = useCallback(() => {
    setDisplayMenu(false);
    searchBarDesktopRef.current?.focusSearchInput();
  }, []);

  return (
    <div className={styles.leftBlock}>
      {!displayMenu ? (
        <Button
          className={styles.openMenuBtn}
          onClick={openMenuClickHandler}
          type="button"
        >
          <BurgerMenu />
        </Button>
      ) : (
        <SearchButton
          className={styles.searchBtn}
          onClick={showSearchBarBtnHandler}
        />
      )}
    </div>
  );
}

function SearchBarBlock({ isSticky }: { isSticky: boolean }) {
  const { searchBarDesktopRef } = useSearchBar();
  const isLargeLaptop = useMediaQuery({ query: LARGE_LAPTOP_AND_DESKTOP });
  const closeSearchBar = useCallback(
    (type?: OnCloseTypeKeys) => {
      searchBarDesktopRef.current?.clearSearchInput();

      if (
        type === OnCloseType.REDIRECT_CATEGORY ||
        type === OnCloseType.REDIRECT_PRODUCT ||
        type === OnCloseType.TRIGGER_SEARCH
      ) {
        searchBarDesktopRef.current?.closeMerch();
      }
    },
    [searchBarDesktopRef.current]
  );

  if (isSticky && isLargeLaptop) return null;

  return (
    <SearchBar
      classes={{ container: styles.searchBarContainer }}
      ref={searchBarDesktopRef as MutableRefObject<SearchBarRefType>}
      onClose={closeSearchBar}
      onClickAway={closeSearchBar}
      placementId={'header-container'}
      id={'header-search'}
    />
  );
}

function RightBlock({
  displayMenu,
  isLargeLaptop,
  setDisplayMenu,
}: {
  displayMenu: boolean;
  isLargeLaptop: boolean;
  setDisplayMenu: (displayMenu: boolean) => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.rightBlock}>
      {displayMenu && !isLargeLaptop ? (
        <button
          className={styles.closeMenuBtn}
          type="button"
          onClick={() => setDisplayMenu(false)}
          aria-label={t('LABEL_CLOSE')}
        >
          <CloseIcon />
        </button>
      ) : (
        <RightNavigationLinks />
      )}
    </div>
  );
}
