import React from 'react';
import useSWRImmutable from 'swr/immutable';
import { fetcher } from 'services/fetcher';
import { Site } from 'types/Site';
import useEnv from 'hooks/useEnv';

/**
 * Header banner - Display at the top of the website. Return by Site.cxml API.
 */
export default function HeaderBanner(): JSX.Element {
  const { datetimeApp } = useEnv();
  const { data } = useSWRImmutable<Site>(
    `${process.env.REACT_APP_URL_API_CATALOG}/json/Custom/Site.cxml?${datetimeApp}`,
    fetcher
  );

  const htmlContent = data?.Zones[0]?.Controls[0]?.Actions[0]?.HtmlContent;

  return (
    <div
      id={'headerBanner'}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
}
