import React, { ComponentPropsWithoutRef, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { getUri } from 'services/generic';

import styles from './Ariane.module.scss';

type PropsType = {
  items?: ItemType[];
  style?: CSSProperties;
  type?: string;
  classes?: ClassesType;
};

type ItemType = {
  label: string;
  url?: string;
};

type ClassesType = {
  nav?: string;
};

export const TYPE_ACCOUNT = 'account';
export const TYPE_PUBLIC = 'public';

export default function Ariane({
  items,
  style,
  type = TYPE_ACCOUNT,
  classes,
}: PropsType): JSX.Element | null {
  const { t } = useTranslation();

  if (!items) return null;

  return (
    <nav
      aria-label="breadcrumb"
      className={classNames(styles.breadcrumb, classes?.nav, {
        [styles.account]: type === TYPE_ACCOUNT,
        [styles.public]: type === TYPE_PUBLIC,
      })}
      style={style}
    >
      <ol className={styles['breadcrumb-items']}>
        <BreadcrumbItem>
          <BreadcrumbLink href={getUri('/')}>{t('LABEL_HOME')}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        {items.map((item, i) => {
          const { label, url } = item;
          const isLastItem = i === items.length - 1;

          return (
            <React.Fragment key={i}>
              <BreadcrumbItem>
                {!isLastItem && (
                  <BreadcrumbLink href={url || '#'}>{label}</BreadcrumbLink>
                )}
                {isLastItem && <BreadcrumbPage>{label}</BreadcrumbPage>}
              </BreadcrumbItem>
              {!isLastItem && <BreadcrumbSeparator />}
            </React.Fragment>
          );
        })}
      </ol>
    </nav>
  );
}

function BreadcrumbLink({
  className,
  href,
  ...props
}: ComponentPropsWithoutRef<'a'>) {
  return (
    <Link
      className={classNames(styles.link, className)}
      to={href as string}
      {...props}
    />
  );
}

function BreadcrumbItem({
  className,
  ...props
}: ComponentPropsWithoutRef<'li'>): JSX.Element {
  return (
    <li
      className={classNames(styles['breadcrumb-item'], className)}
      {...props}
    />
  );
}

function BreadcrumbPage({
  className,
  ...props
}: ComponentPropsWithoutRef<'span'>) {
  return (
    <span
      className={classNames(styles.link, styles.current, className)}
      role="link"
      aria-disabled="true"
      aria-current="page"
      {...props}
    />
  );
}

function BreadcrumbSeparator(): JSX.Element {
  return (
    <BreadcrumbItem
      role="presentation"
      aria-hidden="true"
      className={styles.separator}
    >
      <span>|</span>
    </BreadcrumbItem>
  );
}
