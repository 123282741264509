import React from 'react';
import type { ComponentProps } from 'react';
import { SvgIcon } from '@material-ui/core';

type SvgIconProps = ComponentProps<typeof SvgIcon>;

export default function CloseIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon fontSize={'inherit'} viewBox={'0 0 32 32'} {...props}>
      <path d="M2.407 0.71c-0 0-0.001 0-0.002 0-0.937 0-1.697 0.76-1.697 1.697 0 0.477 0.197 0.909 0.514 1.217l0 0 26.862 26.86c0.309 0.322 0.743 0.522 1.224 0.522 0.937 0 1.697-0.76 1.697-1.697 0-0.481-0.2-0.915-0.521-1.224l-0.001-0.001-26.86-26.862c-0.309-0.317-0.739-0.513-1.216-0.513-0.001 0-0.001 0-0.002 0h0z"></path>
      <path d="M29.252 0.71c-0.459 0.014-0.869 0.208-1.166 0.513l-26.862 26.862c-0.307 0.307-0.497 0.731-0.497 1.2 0 0.937 0.76 1.697 1.697 1.697 0.469 0 0.894-0.19 1.201-0.498v0l26.86-26.86c0.318-0.309 0.516-0.741 0.516-1.218 0-0.937-0.76-1.697-1.697-1.697-0.018 0-0.036 0-0.054 0.001l0.003-0z"></path>
    </SvgIcon>
  );
}
