import {
  GTM_CATEGORY_ORDER,
  GTM_CATEGORY_PAGE,
  GTM_CATEGORY_PRODUCTS,
  GTM_CATEGORY_SEARCH,
  GTM_CATEGORY_USER,
  GTM_PAGE_ACCOUNT,
  GTM_PAGE_CART,
  GTM_PAGE_CONFIRMATION,
  GTM_PAGE_DIRECT_ORDER,
  GTM_PAGE_ERROR,
  GTM_PAGE_HOME,
  GTM_PAGE_LIST,
  GTM_PAGE_LOGIN,
  GTM_PAGE_OTHER,
  GTM_PAGE_PAYMENT,
  GTM_PAGE_PRODUCT,
  GTM_PAGE_SEARCH,
  GTM_PAGE_SHIPPING,
} from './gtmPageView';
import { GtmDataMappingType } from 'types/gtm/GtmPageView';

export const GTM_DATA_KEYS: string[] = [
  'event',
  'env_country',
  'env_currency',
  'env_language',
  'env_work',
  'env_timestamp',
  'env_version',
  'env_internal_traffic',
  'env_date',
  'page_category_1',
  'page_category_2',
  'page_category_3',
  'page_error',
  'page_type',
  'page_title',
  'page_filters',
  'page_name',
  'page_url',
  'page_referrer',
  'user_id',
  'user_customer_id',
  'user_email',
  'user_type',
  'user_login_status',
  'user_login_type',
  'user_order_number',
  'products',
  'product',
  'list_products',
  'order_products',
  'order_cart_id',
  'order_timestamp',
  'order_total_item_quantity',
  'order_amount_ati',
  'order_amount_tf',
  'order_shipping_value',
  'order_tax_value',
  'order_id',
  'order_unique_items',
  'order_payment_method',
  'order_shipping_method',
  'order_coupon_code',
  'order_discount_amount_tf',
  'order_discount_amount_ati',
  'order_shipping_country',
  'order_checkout_type',
  'search_term',
  'search_status',
  'search_result_number',
];

export const GTM_DATA_MAPPING: GtmDataMappingType = {
  [GTM_PAGE_HOME]: {
    [GTM_CATEGORY_PAGE]: [
      'page_category_1',
      'page_category_2',
      'page_category_3',
      'page_error',
      'page_filters',
      'page_sort',
    ],
    [GTM_CATEGORY_USER]: [],
  },
  [GTM_PAGE_SEARCH]: {
    [GTM_CATEGORY_PAGE]: [
      'page_category_1',
      'page_category_2',
      'page_category_3',
      'page_error',
      'page_filters',
      'page_sort',
    ],
    [GTM_CATEGORY_USER]: [],
    [GTM_CATEGORY_PRODUCTS]: ['access'],
    [GTM_CATEGORY_SEARCH]: [],
  },
  [GTM_PAGE_PRODUCT]: {
    [GTM_CATEGORY_PAGE]: [
      'page_category_1',
      'page_category_2',
      'page_category_3',
      'page_error',
      'page_filters',
      'page_sort',
    ],
    [GTM_CATEGORY_USER]: [],
    [GTM_CATEGORY_PRODUCTS]: ['list_position'],
  },
  [GTM_PAGE_LIST]: {
    [GTM_CATEGORY_PAGE]: ['error'],
    [GTM_CATEGORY_USER]: [],
    [GTM_CATEGORY_PRODUCTS]: ['access'],
  },
  [GTM_PAGE_ERROR]: {
    [GTM_CATEGORY_PAGE]: [
      'page_category_1',
      'page_category_2',
      'page_category_3',
      'page_filters',
      'page_sort',
    ],
    [GTM_CATEGORY_USER]: [],
  },
  [GTM_PAGE_ACCOUNT]: {
    [GTM_CATEGORY_PAGE]: [
      'page_category_1',
      'page_category_2',
      'page_category_3',
      'page_error',
      'page_filters',
      'page_sort',
    ],
    [GTM_CATEGORY_USER]: [],
  },
  [GTM_PAGE_DIRECT_ORDER]: {
    [GTM_CATEGORY_PAGE]: [
      'page_category_1',
      'page_category_2',
      'page_category_3',
      'page_error',
      'page_filters',
      'page_sort',
    ],
    [GTM_CATEGORY_USER]: [],
  },
  [GTM_PAGE_CART]: {
    [GTM_CATEGORY_PAGE]: [
      'page_category_1',
      'page_category_2',
      'page_category_3',
      'page_error',
      'page_filters',
      'page_sort',
    ],
    [GTM_CATEGORY_USER]: [],
    [GTM_CATEGORY_PRODUCTS]: ['list_position', 'access'],
    [GTM_CATEGORY_ORDER]: [
      'order_timestamp',
      'order_shipping_value',
      'order_payment_method',
      'order_shipping_method',
      'order_coupon_code',
      'order_discount_amount_tf',
      'order_discount_amount_ati',
      'order_shipping_country',
    ],
  },
  [GTM_PAGE_LOGIN]: {
    [GTM_CATEGORY_PAGE]: [
      'page_category_1',
      'page_category_2',
      'page_category_3',
      'page_error',
      'page_filters',
      'page_sort',
    ],
    [GTM_CATEGORY_USER]: [],
    [GTM_CATEGORY_PRODUCTS]: ['list_position', 'access'],
    [GTM_CATEGORY_ORDER]: [
      'order_timestamp',
      'order_shipping_value',
      'order_payment_method',
      'order_shipping_method',
      'order_discount_amount_tf',
      'order_discount_amount_ati',
      'order_shipping_country',
    ],
  },
  [GTM_PAGE_SHIPPING]: {
    [GTM_CATEGORY_PAGE]: [
      'page_category_1',
      'page_category_2',
      'page_category_3',
      'page_error',
      'page_filters',
      'page_sort',
    ],
    [GTM_CATEGORY_USER]: [],
    [GTM_CATEGORY_PRODUCTS]: ['list_position', 'access'],
    [GTM_CATEGORY_ORDER]: [
      'order_timestamp',
      'order_shipping_value',
      'order_payment_method',
      'order_shipping_method',
      'order_discount_amount_tf',
      'order_discount_amount_ati',
      'order_shipping_country',
    ],
  },
  [GTM_PAGE_PAYMENT]: {
    [GTM_CATEGORY_PAGE]: [
      'page_category_1',
      'page_category_2',
      'page_category_3',
      'page_error',
      'page_filters',
      'page_sort',
    ],
    [GTM_CATEGORY_USER]: [],
    [GTM_CATEGORY_PRODUCTS]: ['list_position', 'access'],
    [GTM_CATEGORY_ORDER]: ['order_timestamp', 'order_payment_method'],
  },
  [GTM_PAGE_CONFIRMATION]: {
    [GTM_CATEGORY_PAGE]: [
      'page_category_1',
      'page_category_2',
      'page_category_3',
      'page_error',
      'page_filters',
      'page_sort',
    ],
    [GTM_CATEGORY_USER]: [],
    [GTM_CATEGORY_PRODUCTS]: ['list_position', 'access'],
    [GTM_CATEGORY_ORDER]: [
      'order_timestamp',
      'order_payment_method',
      'order_discount_amount_tf',
    ],
    [GTM_CATEGORY_ORDER]: [],
  },
  [GTM_PAGE_OTHER]: {
    [GTM_CATEGORY_PAGE]: [
      'page_category_1',
      'page_category_2',
      'page_category_3',
      'page_error',
      'page_filters',
      'page_sort',
    ],
    [GTM_CATEGORY_USER]: [],
  },
};

export const GTM_PRODUCTS_KEY_NAME = {
  [GTM_PAGE_LIST]: 'list_products',
  [GTM_PAGE_SEARCH]: 'list_products',
  [GTM_PAGE_PRODUCT]: 'product',
  [GTM_PAGE_CART]: 'order_products',
  [GTM_PAGE_LOGIN]: 'order_products',
  [GTM_PAGE_SHIPPING]: 'order_products',
  [GTM_PAGE_PAYMENT]: 'order_products',
  [GTM_PAGE_CONFIRMATION]: 'order_products',
};
