import React, { useRef, useState } from 'react';

import { SearchBarRefType } from 'components/SearchBar/SearchBar';
import SearchBarContext from 'contexts/searchBarContext';

export default function SearchBarProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const searchBarRef = useRef<SearchBarRefType | null>(null);
  const searchBarDesktopRef = useRef<SearchBarRefType | null>(null);
  const [searchKeyword, setSearchKeyword] = useState('');

  return (
    <SearchBarContext.Provider
      value={{
        searchBarRef,
        searchBarDesktopRef,
        searchKeyword,
        setSearchKeyword,
      }}
    >
      {children}
    </SearchBarContext.Provider>
  );
}
