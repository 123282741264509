import { useCookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';

import { ENV_COOKIE_DOMAIN, ENV_COOKIE_PATH } from 'settings/env';

const COOKIE_NAME = 'plusSizeSkus';
const COOKIE_OPTIONS = {
  domain: ENV_COOKIE_DOMAIN || undefined,
  path: ENV_COOKIE_PATH,
  sameSite: 'lax',
} as CookieSetOptions;

type ReturnType = {
  storeLSSku: (sku: string, isLSProduct: boolean) => void;
  removeLSSku: (sku: string, skusArg?: string[]) => void;
  removeUnusedSkus: (cartSkus: string[]) => void;
  isLSSku: (sku: string) => boolean;
  deleteCookie: () => void;
};

// TODO: Replace by local storage solution if tapbuy, and replace this hook by a service
export default function useLargeSizeCookie(): ReturnType {
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAME]);

  function getLSSkus(): string[] {
    return cookies[COOKIE_NAME] ?? [];
  }

  function storeLSSku(sku: string, isLSProduct: boolean): void {
    const skus = getLSSkus();

    if (!isLSProduct) {
      return removeLSSku(sku, skus);
    }

    if (skus.includes(sku)) return;

    skus.push(sku);
    removeCookie(COOKIE_NAME);
    saveCookie(skus);
  }

  function removeLSSku(sku: string, skusArg?: string[]): void {
    const skus = skusArg ?? getLSSkus();
    const skuIndex = skus?.indexOf(sku);

    if (skuIndex === -1) {
      return;
    }

    const newSkus = skus.filter(item => item !== sku);
    deleteCookie();
    newSkus.length && saveCookie(newSkus);
  }

  function removeUnusedSkus(cartSkus: string[]): void {
    const skus = getLSSkus();
    const filteredSkus = skus.filter(item =>
      cartSkus.includes(item.toString())
    );

    deleteCookie();
    saveCookie(filteredSkus);
  }

  function saveCookie(skus: string[]): void {
    setCookie(COOKIE_NAME, skus, { ...COOKIE_OPTIONS, maxAge: 15768000 });
  }

  function deleteCookie(): void {
    removeCookie(COOKIE_NAME, COOKIE_OPTIONS);
  }

  function isLSSku(sku: string): boolean {
    return getLSSkus().indexOf(sku) !== -1;
  }

  return { storeLSSku, removeLSSku, removeUnusedSkus, isLSSku, deleteCookie };
}
