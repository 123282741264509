import React from 'react';
import type { ComponentProps } from 'react';
import { SvgIcon } from '@material-ui/core';

type SvgIconProps = ComponentProps<typeof SvgIcon>;

export default function BurgerMenu(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon fontSize={'inherit'} viewBox={'0 0 45 32'} {...props}>
      <path d="M1.939 0.015c-0.008-0-0.018-0-0.027-0-1.060 0-1.918 0.859-1.918 1.918s0.859 1.918 1.918 1.918c0.010 0 0.019-0 0.029-0l-0.001 0h40.922c0.008 0 0.018 0 0.027 0 1.060 0 1.918-0.859 1.918-1.918s-0.859-1.918-1.918-1.918c-0.010 0-0.019 0-0.029 0l0.001-0z"></path>
      <path d="M1.939 14.082c-0.008-0-0.018-0-0.027-0-1.060 0-1.918 0.859-1.918 1.918s0.859 1.918 1.918 1.918c0.010 0 0.019-0 0.029-0l-0.001 0h40.922c0.008 0 0.018 0 0.027 0 1.060 0 1.918-0.859 1.918-1.918s-0.859-1.918-1.918-1.918c-0.010 0-0.019 0-0.029 0l0.001-0z"></path>
      <path d="M1.939 28.149c-0.008-0-0.018-0-0.027-0-1.060 0-1.918 0.859-1.918 1.918s0.859 1.918 1.918 1.918c0.010 0 0.019-0 0.029-0l-0.001 0h40.922c0.008 0 0.018 0 0.027 0 1.060 0 1.918-0.859 1.918-1.918s-0.859-1.918-1.918-1.918c-0.010 0-0.019 0-0.029 0l0.001-0z"></path>
    </SvgIcon>
  );
}
