import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { userContext } from 'contexts/userContext';
import { GtmUserCookieType } from 'types/GtmUserCookie';
import { useCustomer } from 'hooks/useCustomer';
import { env } from 'settings/env';

function UserProvider(props: { children: JSX.Element }): JSX.Element {
  const { children } = props;
  const { customer } = useCustomer();
  const [cookies] = useCookies(['retarg']);
  const [cUserId, setCUserId] = useState<string | undefined>();
  const [retarg, setRetarg] = useState<string | undefined>();
  const retargCookie = cookies['retarg'];
  const [gtmUserCookie, setGtmUserCookie] = useState<
    GtmUserCookieType | undefined
  >();

  useEffect(() => {
    if (!customer) return;
    if (retargCookie && !retarg) {
      setRetarg(undefined);
    }

    if (!retargCookie) {
      setRetarg('');

      return;
    }

    fetch(`${process.env.REACT_APP_URL_API_CUSTOMER}/updateSession`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': `${process.env.REACT_APP_API_SYMFONY_API_KEY}`,
      },
      body: JSON.stringify({ retarg: cookies.retarg }),
    })
      .then(async response => {
        if (response.ok) {
          const data = await response.json();
          const cuserId = data?.OCTIPASS?.match(
            /[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}/
          );

          if (cUserId?.length === 1) setCUserId(cuserId[0]);
        }
      })
      .catch(() => console.warn('Error during updateSession API'));

    fetch(`${env.ENV_API_URL_CUSTOMER}/user/retarg`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'X-AUTH-TOKEN': `${env.ENV_API_KEY_SYMFONY}` },
      body: JSON.stringify({ retarg: retargCookie }),
    })
      .then(async response => {
        if (response.ok) {
          const data = await response.json();
          if (data.CODE_RETOUR === '0') {
            const {
              NBCDE: nbCommand,
              OPTIONMAIL: optionEmail,
              DATEDERNCDE: dateLastCommand,
              CATEGORIE: category,
            } = data;

            setGtmUserCookie({
              user_id: retargCookie,
              user_logged: customer.isLoggedIn ? 'yes' : 'no',
              user_email_optin: optionEmail === 'O' ? 'yes' : 'no',
              user_nb_command: nbCommand,
              user_status: nbCommand > 0 ? 'client' : 'prospect',
              customer_last_order: dateLastCommand,
              user_category: category.length > 0 ? category?.slice(0, 1) : 1,
            });
          }
        }
      })
      .finally(() => setRetarg(retargCookie));
  }, [customer, retargCookie]);

  return (
    <userContext.Provider
      value={{
        retarg,
        cUserId,
        gtmUserCookie,
      }}
    >
      {children}
    </userContext.Provider>
  );
}

export default UserProvider;
